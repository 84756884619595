import * as React from 'react'

import { Button, Icon } from 'unno-comutils/ui'

import { Body, Footer, FooterLink, Header } from '../components/layout'
import { ITEM_CONTACTs } from '../service/variable'

export default function _error (props: any) {
    return <>
        <Header/>

        <Body flex className="-bg_star">
            <div className={'page-error'}>
                <div className={'_error-code'}>4<span className="_yellow">0</span>4</div>

                <div className={'_head'}>OBI ต้องขอโทษที่ไม่สามารถบริการได้ในขณะนี้</div>
                <div className={'_detail'}>
                    เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่ในภายหลัง <br/>
                    ทางทีมงาน OBI ต้องข้ออภัยเป็นอย่างสูง
                </div>

                <div className={'_head-sm'}>หากต้องการติดต่อสอบถาม</div>

                <div className={'_contacts'}>
                    {ITEM_CONTACTs.map((o: any, x: number) => <a key={'o_' + o.key} className={'_item'} href={o.url} target="_blank">
                        <Icon className={'_icon'} name={o.icon} brand={o.brand} solid={!o.brand}/>
                        <div className={'_text'}>{o.name}</div>
                    </a>)}
                </div>

                <button className={'_button'}>ลองใหม่</button>

                <div className={'_note'}>Problem code : A19230</div>
            </div>
        </Body>

        <Footer/>
        <FooterLink/>
    </>
}